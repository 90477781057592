<template>
  <div class="bg-white shadow-lg rounded-md px-5 py-6">
    <a-spin v-if="verifying" />
    <div v-else>
      <h2 class="text-2xl font-bold mb-5">Email Verification Successful</h2>
      <p>
        Your email account has been verified successfully, kindly proceed to
        <router-link to="/signin">login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import authApi from '../../api/auth'
export default {
  layout: 'auth',
  data() {
    return {
      token: '',
      verifying: false,
    }
  },
  created() {
    this.token = this.$route.params.email_token
    this.verifyToken()
  },
  methods: {
    async verifyToken() {
      this.verifying = true
      const req = await authApi(this.axios).verifyEmail({
        token: this.token,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to verify Email',
          description: req.message,
        })
        setTimeout(() => {
          this.$router.push('/signin')
        }, 3000)
        return
      }
      this.verifying = false
      setTimeout(() => {
        this.$router.push('/signin')
      }, 3000)
    },
  },
}
</script>
