import { errorResponse } from './response'

const authApi = $axios => {
  const http = $axios
  return {
    async login({ email, password }) {
      const payload = { email, password }
      try {
        const req = await http.post('/auth/login', payload)
        return req
      } catch (error) {
        console.log({ error })
        return errorResponse(error)
      }
    },
    async register(data) {
      try {
        const req = await http.post('/auth/register', data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async recoverPassword({ email }) {
      try {
        const req = await http.get(`/auth/password/recover?email=${email}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async verifyEmail({ token }) {
      try {
        const req = await http.get(`/auth/verify-email/${token}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async resendEmailVerification({ email }) {
      try {
        const req = await http.get(`/auth/verify-token/resend?email=${email}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async changePassword({ oldPassword, password, confirmPassword }) {
      try {
        const data = { oldPassword, password, confirmPassword }
        const req = await http.post('/auth/password/change', data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async resetPassword({ token, password, confirmPassword }) {
      try {
        const req = await http.post(`/auth/password/reset/${token}`, {
          password,
          confirmPassword
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    }
  }
}

export default authApi
